import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";

@Directive({
    selector: "[cfiViewport]"
})
export class ViewportDirective implements OnInit, OnDestroy {
    @Output() isAboveChange = new EventEmitter<boolean>();

    private _intersectionObserver = new IntersectionObserver(
        entries => {
            this.checkForIntersection(entries);
        }
    );

    constructor(private _element: ElementRef) { }

    ngOnInit(): void {
        this._intersectionObserver.observe(this._element.nativeElement);
    }

    ngOnDestroy(): void {
        this._intersectionObserver.unobserve(this._element.nativeElement);
        this._intersectionObserver.disconnect();
    }

    private checkForIntersection(entries: IntersectionObserverEntry[]): void {
        for (const entry of entries) {
            this.isAboveChange.emit(!entry.isIntersecting && entry.boundingClientRect.top < 0);
        }
    }
}
