import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@app/auth";
import { UserCreate } from "@app/core/model/user.model";
import { UserService } from "@app/core/services/user.service";
import { indicate } from "@app/shared/operators";
import { Subject } from "rxjs";

import { RegisterPage } from "../form-section.class";

@Component({
    selector: "cfi-additional-data-form",
    templateUrl: "./additional-data-form.component.html"
})
export class AdditionalDataFormComponent extends RegisterPage {
    form: FormGroup;

    isLoading$ = new Subject<boolean>();

    constructor(
        private _fb: FormBuilder,
        private _userService: UserService,
        private _authService: AuthService,
        private _router: Router
    ) {
        super();
        this.form = this._fb.group({
            requestedFoundationName: ["", [Validators.required]],
            jobTitle: [""]
        });
    }

    addData(): void {
        if (this.form.valid) {
            const user: UserCreate = {
                ...this.form.value,
                email: this._authService.email ? this._authService.email : "",
                firstName: this._authService.givenName ? this._authService.givenName : "",
                lastName: this._authService.familyName ? this._authService.familyName : ""
            };

            this._userService.create(user)
                .pipe(
                    indicate(this.isLoading$)
                ).subscribe(_ => {
                    this._router.navigate(["thank-you"]);
                });
        }
    }
}
