import { Injectable } from "@angular/core";
import { AuthService } from "@app/auth";
import { User, UserCreate, UserSearchCriteria, UserStatus, UserUpdate } from "@app/core/model/user.model";
import { Page } from "@app/shared/paginator/paginator.models";
import { Observable } from "rxjs";

import { ApiClientService } from "./api-client.service";

@Injectable({
    providedIn: "root"
})
export class UserService {
    constructor(
        private _apiClientService: ApiClientService,
        private _authService: AuthService
    ) { }

    get(userId: number): Observable<User> {
        return this._apiClientService.get(`${appConfig.apiUrl}/user/{userId}`, { segmentParams: { userId } });
    }

    me(): Observable<User> {
        const { userId } = this._authService;
        return this.get(userId);
    }

    list(searchCriteria: UserSearchCriteria): Observable<Page<User>> {
        return this._apiClientService.get(`${appConfig.apiUrl}/users`, { queryParams: searchCriteria });
    }

    create(user: UserCreate): Observable<void> {
        return this._apiClientService.post(`${appConfig.apiUrl}/user`, { data: user });
    }

    update(userId: number, user: UserUpdate): Observable<void> {
        return this._apiClientService.patch(`${appConfig.apiUrl}/user/{userId}`, { segmentParams: { userId }, data: user });
    }

    approve(userId: number, foundationExternalUrn: string): Observable<void> {
        return this.update(userId, {
            status: UserStatus.Approved,
            foundationUrnExternal: foundationExternalUrn
        });
    }

    reject(userId: number): Observable<void> {
        return this.update(userId, { status: UserStatus.Rejected });
    }

    delete(userId: number): Observable<void> {
        return this._apiClientService.delete(`${appConfig.apiUrl}/user/{userId}`, { segmentParams: { userId } });
    }

    signout(userId: number): Observable<void> {
        return this._apiClientService.put(`${appConfig.apiUrl}/user/{userId}/signout`, { segmentParams: { userId } });
    }
}
