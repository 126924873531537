import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthCallbackComponent } from "./auth-callback/auth-callback.component";
import { AuthSilentCallbackComponent } from "./auth-silent-callback/auth-silent-callback.component";

const routes: Routes = [
    {
        path: "auth-callback",
        component: AuthCallbackComponent
    },
    {
        path: "auth-silent-callback",
        component: AuthSilentCallbackComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
