import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "@app/auth";
import { Role } from "@app/core/common/enums";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

interface NavigationRoute {
    label: string;
    url: string;
    routerLinkActiveOptions?: {
        exact: boolean;
    };
}

@Component({
    selector: "cfi-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
    menuOpen = false;
    role = Role;

    adminRoutes: NavigationRoute[] = [
        {
            label: "Foundations",
            url: "/foundations/list"
        },
        {
            label: "Surveys",
            url: "/surveys"
        },
        {
            label: "User accounts",
            url: "/user-accounts"
        }
    ];

    userRoutes: NavigationRoute[] = [
        {
            label: "Home",
            url: "/",
            routerLinkActiveOptions: {
                exact: true
            }
        },
        {
            label: "My foundation",
            url: "/foundations/my-foundation/preview"
        },
        {
            label: "My surveys",
            url: "/survey-list"
        },
        {
            label: "Benchmarking",
            url: "/benchmarking"
        }
    ];

    manageRoutes: NavigationRoute[] = [
        {
            label: "Welcome Page",
            url: "/manage/welcome-page"
        }
    ];

    authStatus$!: Observable<boolean>;
    showNavigationButtons$: Observable<boolean>;

    constructor(private _authService: AuthService, private _router: Router) { }

    @HostListener("window:storage", ["$event"])
    onStorageChange(event: StorageEvent): void {
        if (event.key === "signout" && event.newValue === "true" && !event.oldValue) {
            this.signout();
        }
    }

    ngOnInit(): void {
        this.authStatus$ = this._authService.authStatus$;

        this.showNavigationButtons$ = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(navigation => {
                const { url } = (navigation as NavigationEnd);
                return !["/register", "/register/thank-you", "/logout", "/thank-you", "/additional-data", "/request-rejected"]
                    .includes(url);
            }),
            distinctUntilChanged()
        );
    }

    login(): void {
        this._authService.login();
    }

    signout(): void {
        this._authService.signout();
    }

    getUserName(): Nullable<string> {
        return this._authService.name;
    }

    isOnBasicPage(): boolean {
        return ["/additional-data", "/request-rejected", "/thank-you"].includes(this._router.url);
    }
}
