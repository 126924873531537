import { NgModule } from "@angular/core";

import { LoginModule } from "./account/login/login.module";
import { RegisterModule } from "./account/register/register.module";
import { FeaturesRoutingModule } from "./features-routing.module";

@NgModule({
    imports: [
        FeaturesRoutingModule,
        LoginModule,
        RegisterModule
    ]
})
export class FeaturesModule { }
