import { Injectable } from "@angular/core";
import { AuthService } from "@app/auth";

@Injectable({
    providedIn: "root"
})
export class CandidPopupService {
    constructor(
        private _authService: AuthService
    ) { }

    openSupport(): void {
        const url = new URL("https://support.candid.org/case.html");

        url.search = new URLSearchParams({
            type: "cfi",
            mode: "popup",
            name: this._authService.name ?? "",
            email: this._authService.email ?? "",
            context: window.location.href
        }).toString();

        window.open(url.toString(), "popup", "location=0,width=750,height=600");
    }
}
