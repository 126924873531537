import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "@app/material.module";

import { LoadingDirective } from "./loading.directive";
import { SpinnerComponent } from "./spinner.component";

@NgModule({
    declarations: [
        SpinnerComponent,
        LoadingDirective
    ],
    imports: [
        MaterialModule,
        CommonModule
    ],
    exports: [
        SpinnerComponent,
        LoadingDirective
    ]
})
export class LoaderModule { }
