import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { DialogInfoComponent } from "../dialog-info/dialog-info.component";

@Component({
    selector: "cfi-dialog-logging-process",
    templateUrl: "./dialog-logging-process.component.html",
    styleUrls: ["./dialog-logging-process.component.scss"]
})
export class DialogLoggingProcessComponent {
    constructor(public dialogRef: MatDialogRef<DialogInfoComponent>) { }
}
