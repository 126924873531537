<div *cfiLoading="(isLoading$ | async) || ((text$ | async) === null)">
    <div *ngIf="text$ | async as text">
        <div class="section-black">
            <div class="site-container grid">
                <div class="col-12">
                    <div class="announcement-header" [innerHTML]="text.announcementHeader"> </div>
                </div>
            </div>
        </div>

        <div class="section-blue">
            <div class="site-container grid">
                <div class="col-12">
                    <h2 class="welcome-title">Contribute to the field’s collective knowledge</h2>
                    <p class="welcome-paragraph">Community foundations: Participate in our ongoing effort to track the state of the field
                        and inform your own decision-making.</p>
                </div>
            </div>
        </div>

        <div class="section-border">
            <div class="site-container grid annual-survey-section">
                <div class="col-4">
                    <div [innerHTML]="text.surveysHeader"></div>
                    <ng-container>
                        <div class="col-6">
                            <div class="text"
                                 [innerHTML]="text.surveysDescription">
                            </div>
                            <button mat-flat-button
                                    color="primary"
                                    routerLink="/survey-list">
                                My surveys
                            </button>
                        </div>
                    </ng-container>

                </div>
                <div class="col-4 survey-image">
                    <img src="assets/images/survey-photo.png">
                </div>
                <div class="col-4 instructions">
                    <div [innerHTML]="text.benchmarkHeader"></div>
                    <p [innerHTML]="text.benchmarkDescription"></p>
                    <button mat-flat-button
                            color="primary"
                            routerLink="/benchmarking">Benchmark against peers</button>
                </div>
            </div>
        </div>

        <div class="section-border">
            <div class="site-container grid help-section">
                <div class="col-12">
                    <h2>Need help?</h2>
                </div>
                <div class="col-4">
                    <div class="faq-image">
                        <img src="assets/images/faq-photo.png">
                    </div>
                    <p class="font-small font-secondary caption">FAQs</p>
                    <h4>See frequently asked questions.</h4>
                    <a href="https://cof.org/cf-insights/faqs"
                       target="_blank">
                        <button mat-flat-button
                                color="primary">See FAQs</button>
                    </a>
                </div>
                <div class="col-2"></div>
                <div class="col-4">
                    <div class="issue-image">
                        <img src="assets/images/issue-photo.png">
                    </div>
                    <p class="font-small font-secondary caption">Contact us</p>
                    <h4>Need further assistance?</h4>
                    <a href="mailto:cfinsights@cof.org">
                        <button mat-flat-button
                                color="primary">Contact us</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
