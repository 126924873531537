import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { DialogData } from "../dialog-models";

@Component({
    templateUrl: "./dialog-info.component.html"
})
export class DialogInfoComponent {
    constructor(public dialogRef: MatDialogRef<DialogInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}
