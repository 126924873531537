<cfi-register-container>
    <div *cfiLoading="isLoading$ | async">
        <h2 class="title">More details needed</h2>
        <p class="mb-3">
            Provide the following details to gain access to CF Insights,
            and please allow 1-2 business days for an administrator to confirm your access.
        </p>
        <form [formGroup]="form"
              (ngSubmit)="addData()"
              cfiInvalidControlScroll>
            <mat-form-field class="full-width-input">
                <mat-label>Foundation name</mat-label>
                <input matInput
                       formControlName="requestedFoundationName"
                       maxlength="60"
                       required>
                <mat-error>
                    <cfi-validation-feedback controlName="requestedFoundationName"></cfi-validation-feedback>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input mb-3">
                <mat-label>Job title</mat-label>
                <input matInput
                       formControlName="jobTitle"
                       maxlength="35">
            </mat-form-field>
            <div class="buttons">
                <button mat-flat-button
                        color="primary">Submit</button>
                <button mat-flat-button
                        type="button"
                        (click)="goToHomePage()">Back to homepage</button>
            </div>
        </form>
    </div>
</cfi-register-container>
