import { Injectable } from "@angular/core";
import {
    Foundation,
    FoundationComparison,
    FoundationCreate,
    FoundationSearchCriteria,
    FoundationsLookup,
    FoundationUpdate
} from "@app/core/model/foundation.model";
import { FoundationLookup } from "@app/shared/lookups/foundation-lookup/foundation-lookup.model";
import { Page } from "@app/shared/paginator/paginator.models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ApiClientService } from "./api-client.service";

@Injectable({
    providedIn: "root"
})
export class FoundationService {
    constructor(
        private _apiClientService: ApiClientService
    ) { }

    getFoundation(foundationId: number): Observable<Foundation> {
        return this._apiClientService.get(`${appConfig.apiUrl}/foundation/{foundationId}`, { segmentParams: { foundationId } });
    }

    getFoundationComparison(foundationId: number): Observable<FoundationComparison> {
        return this._apiClientService.get(`${appConfig.apiUrl}/foundation/{foundationId}/comparison`, { segmentParams: { foundationId } });
    }

    getMyFoundation(): Observable<Foundation> {
        return this._apiClientService.get(`${appConfig.apiUrl}/foundation`);
    }

    createMyFoundation(foundation: FoundationCreate): Observable<Foundation> {
        return this._apiClientService.post(`${appConfig.apiUrl}/foundation/create`, { data: foundation });
    }

    listFoundations(searchCriteria: FoundationSearchCriteria): Observable<Page<Foundation>> {
        return this._apiClientService.get(`${appConfig.apiUrl}/foundations`, { queryParams: searchCriteria });
    }

    updateFoundation(foundationId: number, foundation: FoundationUpdate): Observable<Foundation> {
        return this._apiClientService.patch(`${appConfig.apiUrl}/foundation/{foundationId}`, {
            segmentParams: { foundationId },
            data: foundation
        });
    }

    deleteFoundation(foundationId: number): Observable<Foundation> {
        return this._apiClientService.delete(`${appConfig.apiUrl}/foundation/{foundationId}`, { segmentParams: { foundationId } });
    }

    lookupFoundations(foundationName: string): Observable<Page<FoundationLookup>> {
        return this._apiClientService.get<Page<FoundationsLookup>>(`${appConfig.apiUrl}/foundations/lookup`,
            { queryParams: { foundationName } })
            .pipe(
                map(foundations => this.mapLookup(foundations))
            );
    }

    lookupFoundationsAffiliates(foundationId: number, foundationName: string): Observable<Page<FoundationLookup>> {
        return this._apiClientService.get<Page<FoundationsLookup>>(`${appConfig.apiUrl}/foundations/affiliates`,
            { queryParams: { foundationId, foundationName } })
            .pipe(
                map(foundations => this.mapLookup(foundations))
            );
    }

    private mapLookup(foundationsLookup: Page<FoundationsLookup>): Page<FoundationLookup> {
        const items = foundationsLookup.items
            .map(f => ({
                id: f.id,
                name: f.foundationName,
                urn: f.urn,
                externalUrn: f.externalUrn,
                state: f.state,
                isGhostFoundation: f.isGhostFoundation
            }));

        return { ...foundationsLookup, items };
    }
}
