import { Component, Input, OnInit, Optional } from "@angular/core";
import { AbstractControl, FormGroupDirective } from "@angular/forms";

interface MinLength {
    requiredLength: number;
}

interface Min {
    min: number;
}

interface Max {
    max: number;
}

type InputType = "field" | "section";

@Component({
    selector: "cfi-validation-feedback",
    templateUrl: "./validation-feedback.component.html"
})
export class ValidationFeedbackComponent implements OnInit {
    @Input() control: Nullable<AbstractControl>;
    @Input() controlName: Nullable<string>;
    @Input() inputType: InputType = "field";

    constructor(@Optional() private _formGroup: FormGroupDirective) { }

    getValidationError(): string {
        if (this.control?.untouched) {
            return "";
        }

        if (this.control?.hasError("matDatepickerParse")) {
            return "Date format is incorrect.";
        }

        if (this.control?.hasError("required")) {
            return `Required ${this.inputType}.`;
        }

        if (this.control?.hasError("digits")) {
            return `Please enter ${this.control.getError("digits") as string} digits.`;
        }

        if (this.control?.hasError("minlength")) {
            return `Field has to have at least ${(this.control.getError("minlength") as MinLength).requiredLength} characters.`;
        }

        if (this.control?.hasError("incorrectSum")) {
            return "Fields need to sum up to 100%.";
        }

        if (this.control?.hasError("min")) {
            return `Field has to be greater than or equal to ${(this.control.getError("min") as Min).min}.`;
        }

        if (this.control?.hasError("max")) {
            return `Field has to be less than or equal to ${(this.control.getError("max") as Max).max}.`;
        }

        if (this.control?.hasError("email")) {
            return "E-mail format is incorrect.";
        }

        return "";
    }

    ngOnInit(): void {
        this.setupControl();
    }

    private setupControl(): void {
        if (!this.control) {
            if (this.controlName) {
                this.control = this.getControlByName(this.controlName);
            } else {
                throw new Error("Validation Feedback must have [control] or [controlName] inputs");
            }
        }
    }

    private getControlByName(controlName: string): AbstractControl {
        const control = this._formGroup.form.get(controlName);

        if (!control) {
            throw new Error(`Cannot find "${controlName}" control for Validation Feedback.`);
        }

        return control;
    }
}
