import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "@app/material.module";

import { InfoTooltipComponent } from "./info-tooltip.component";
import { InfoPipe } from "./info.pipe";

@NgModule({
    declarations: [
        InfoPipe,
        InfoTooltipComponent
    ],
    imports: [
        MaterialModule,
        CommonModule
    ],
    exports: [
        InfoTooltipComponent,
        InfoPipe
    ]
})
export class InfoTooltipModule { }
