import { NgModule } from "@angular/core";
import { MaterialModule } from "@app/material.module";

import { PageForbiddenComponent } from "./pages/page-forbidden.component";
import { PageNotFoundComponent } from "./pages/page-not-found.component";

@NgModule({
    declarations: [
        PageNotFoundComponent,
        PageForbiddenComponent
    ],
    imports: [
        MaterialModule
    ]
})
export class PageInfoModule { }
