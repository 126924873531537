import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router, RouterState } from "@angular/router";

import { AuthService } from "./auth";
import { DialogLoggingProcessComponent } from "./shared/dialogs/dialog-logging-process/dialog-logging-process.component";

interface RouterStateActivatedRoute {
    firstChild(route: ActivatedRoute): ActivatedRoute;
}

@Component({
    selector: "cfi-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    private _title: Nullable<string>;

    constructor(
        private _matDialog: MatDialog,
        private _authService: AuthService,
        private _titleService: Title,
        private _router: Router
    ) { }

    ngOnInit(): void {
        this.displayLoggingDialog();
        this.setTabTitles();
        // this.setSilentRenew();
    }

    private displayLoggingDialog() {
        this._authService.loggingProcess.subscribe(isLogging => {
            if (isLogging) {
                this._matDialog.open(DialogLoggingProcessComponent, { disableClose: true, width: "450px" });
            } else {
                this._matDialog.closeAll();
            }
        });
    }

    private setTabTitles(): void {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._title = null;
                const pathTitle = this.getPathTitle(this._router.routerState, this._router.routerState.root);
                const title = `${pathTitle || ""}${pathTitle ? " | " : ""}CF Insights`;
                this._titleService.setTitle(title);
            }
        });
    }

    private getPathTitle(state: RouterState, parent: ActivatedRoute): Nullable<string> {
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            this._title = parent.snapshot.data.title;
        }

        if (state && parent) {
            this.getPathTitle(state, (state as unknown as RouterStateActivatedRoute).firstChild(parent));
        }

        return this._title;
    }

    private setSilentRenew(): void {
        const silentInterval = 15;
        setInterval(() => {
            if (this._authService.isAuthenticated && this._authService.tokenExpired) {
                this._authService.loginSilently();
            }
        }, silentInterval * 1000);
    }
}
