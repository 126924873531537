import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CandidPopupService } from "@app/core/services/candid-popup.service";
import { GeneralService, WelcomePageText } from "@app/core/services/general-service";
import { indicate } from "@app/shared/operators";
import { Observable, Subject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

@Component({
    selector: "cfi-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
    text$: Observable<WelcomePageText<SafeHtml>>;
    isLoading$ = new Subject<boolean>();

    constructor(
        private _candidPopupService: CandidPopupService,
        private _generalService: GeneralService,
        private _domSanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.text$ = this.getTextsToDisplay();
    }

    contact(): void {
        this._candidPopupService.openSupport();
    }

    private getTextsToDisplay(): Observable<WelcomePageText<SafeHtml>> {
        return this._generalService.getWelcomePageTextForDisplay().pipe(
            map(text => {
                const santizedText: WelcomePageText<SafeHtml> = {} as any;
                Object.keys(text).forEach((propertyKey => {
                    const key = propertyKey as keyof WelcomePageText<SafeHtml>;
                    santizedText[key] = this._domSanitizer.bypassSecurityTrustHtml(text[key]);
                }));

                return santizedText;
            }),
            indicate(this.isLoading$),
            shareReplay()
        );
    }
}
