import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "yesNo"
})
export class YesNoPipe implements PipeTransform {
    transform(flag: Nullable<boolean>): string {
        if (flag === true) {
            return "Yes";
        }

        if (flag === false) {
            return "No";
        }

        return "";
    }
}
