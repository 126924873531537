<cfi-register-container>
    <div *cfiLoading="isLoading$ | async">
        <h2 class="title">Create a new account</h2>
        <form [formGroup]="registerForm"
              (ngSubmit)="register()"
              cfiInvalidControlScroll>
            <div class="name-inputs">
                <mat-form-field>
                    <mat-label>First name</mat-label>
                    <input matInput
                           formControlName="firstName"
                           maxlength="35"
                           required>
                    <mat-error>
                        <cfi-validation-feedback controlName="firstName"></cfi-validation-feedback>
                    </mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Last name</mat-label>
                    <input matInput
                           formControlName="lastName"
                           maxlength="35"
                           required>
                    <mat-error>
                        <cfi-validation-feedback controlName="lastName"></cfi-validation-feedback>
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="full-width-input">
                <mat-label>Email</mat-label>
                <input matInput
                       formControlName="email"
                       required>
                <mat-error>
                    <cfi-validation-feedback controlName="email"></cfi-validation-feedback>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <mat-label>Foundation name</mat-label>
                <input matInput
                       formControlName="requestedFoundationName"
                       maxlength="60"
                       required>
                <mat-error>
                    <cfi-validation-feedback controlName="requestedFoundationName"></cfi-validation-feedback>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <mat-label>Job title</mat-label>
                <input matInput
                       formControlName="jobTitle"
                       maxlength="35">
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <mat-label>Password</mat-label>
                <input matInput
                       type="password"
                       formControlName="password"
                       required>
                <mat-error>
                    <cfi-password-validator-feedback controlName="password"></cfi-password-validator-feedback>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <mat-label>Confirm password</mat-label>
                <input matInput
                       type="password"
                       formControlName="confirmPassword"
                       required>
                <mat-error>
                    <cfi-password-validator-feedback controlName="confirmPassword"></cfi-password-validator-feedback>
                </mat-error>
            </mat-form-field>
            <div class="buttons">
                <button mat-flat-button
                        color="primary">Register</button>
                <button mat-flat-button
                        type="button"
                        (click)="goToHomePage()">Back to homepage</button>
            </div>
        </form>
    </div>
</cfi-register-container>