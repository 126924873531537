import { SearchCriteria } from "@app/shared/paginator/paginator.models";

export interface FoundationBase {
    status?: FoundationStatusKeys;
    ipa?: boolean;
    foundationCode?: number;
    region: FoundationRegionKeys;
    yearFoundationCreated: number;
    fiscalYearEnd: string;
    isConversionFoundation: boolean;
    conversionFoundationType: Nullable<FoundationConversionTypeKeys>;
    conversionFoundationOtherType: Nullable<string>;
    ruralUrbanArea: RuralUrbanArea;
    areaScope: AreaScopeKeys;
    serviceAreas: ServiceArea[];
    nationalStandardsCompliant: boolean;
}

export interface ServiceArea {
    state: string;
    county: string;
}

export interface RuralUrbanArea {
    rural: boolean;
    urban: boolean;
}

export type FoundationUpdate = FoundationBase;

export interface FoundationCreate extends FoundationBase {
    foundationExternalUrn: string;
}

export interface Foundation extends FoundationBase {
    urn: string;
    foundationExternalUrn: string;
    id: number;
    foundationName: string;
    insightsMember: FoundationMemberKeys;
    ein: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    isGhostFoundation: boolean;
}

export interface FoundationComparison {
    foundation: Foundation;
    foundationSubmitted: Foundation;
}

export interface FoundationExternal {
    urn: string;
    foundationName: string;
    ein: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    insightsMember: FoundationMemberKeys;
    isGhostFoundation: boolean;
}

export interface FoundationsLookup {
    id: number;
    foundationName: string;
    urn: string;
    externalUrn: string;
    state: string;
    isGhostFoundation: boolean;
}

export enum FoundationMember {
    Participant = "Participant",
    Member = "Member"
}

export type FoundationMemberKeys = keyof typeof FoundationMember;

export enum FoundationRegion {
    Midwest = "Midwest",
    South = "South",
    West = "West",
    Northeast = "Northeast"
}

export type FoundationRegionKeys = keyof typeof FoundationRegion;

export enum FoundationStatus {
    Pending = "Pending",
    Approved = "Approved",
    Declined = "Declined",
    Initial = "Initial"
}

export type FoundationStatusKeys = keyof typeof FoundationStatus;

export enum FoundationConversionType {
    Education = "Education",
    Healthcare = "Healthcare",
    Private = "Private",
    Other = "Other"
}

export type FoundationConversionTypeKeys = keyof typeof FoundationConversionType;

export enum RuralUrbanAreaLabels {
    Urban = "Urban",
    Rural = "Rural",
    Both = "Both"
}

export type RuralUrbanAreaLabelsKeys = keyof typeof RuralUrbanAreaLabels;

export enum RuralUrbanAreaOptions {
    rural = "Rural",
    urban = "Urban"
}

export enum AreaScope {
    City = "City",
    County = "County",
    MultiCounty = "MultiCounty",
    Region = "Region",
    State = "State",
    OutsideUS = "OutsideUS"
}

export const TechnologySoftwareOther = "Other";

export type AreaScopeKeys = keyof typeof AreaScope;

export interface FoundationSearchCriteria extends SearchCriteria {
    status: FoundationStatus;
}
