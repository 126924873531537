import { NgModule } from "@angular/core";
import { MaterialModule } from "@app/material.module";

import { LoaderModule } from "../loader/loader.module";
import { DialogActionComponent } from "./dialog-action/dialog-action.component";
import { DialogInfoComponent } from "./dialog-info/dialog-info.component";
import { DialogLoggingProcessComponent } from "./dialog-logging-process/dialog-logging-process.component";

@NgModule({
    declarations: [
        DialogActionComponent,
        DialogInfoComponent,
        DialogLoggingProcessComponent
    ],
    imports: [
        MaterialModule,
        LoaderModule
    ]
})
export class DialogsModule { }
