import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

const sumValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const notFilled = Object.values(control.value).every(v => v === "" || v === null);
    const sum = Object.values(control.value).reduce((acc, val) => Number(acc) + Number(val), 0);
    return (notFilled || sum === 100) ? null : { incorrectSum: true };
};

const customEmailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const email = /^[^@^\s]+@[^@^\s]+\.[^@^\s]{1,}$/;
    return email.test(control.value) ? null : { email: true };
};

const digitsValidator = (digits: number): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
    const exactDigits = new RegExp(`^[0-9]{${String(digits)}}$`);
    return exactDigits.test(control.value) ? null : { digits };
};

export class CustomValidators {
    static sum = sumValidator;
    static email = customEmailValidator;
    static digits = digitsValidator;
}
