import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdditionalDataGuard } from "@app/core/guards/additional-data.guard";

import { AdditionalDataFormComponent } from "./additional-data-form/additional-data-form.component";
import { RejectedComponent } from "./rejected/rejected.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";

const routes: Routes = [
    {
        path: "additional-data",
        canActivate: [AdditionalDataGuard],
        component: AdditionalDataFormComponent
    },
    {
        path: "thank-you",
        component: ThankYouComponent
    },
    {
        path: "request-rejected",
        component: RejectedComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RegisterRoutingModule { }
