import { NgModule } from "@angular/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthModule } from "@app/auth";
import { IConfig, NgxMaskModule } from "ngx-mask";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
import { CoreModule } from "./core/core.module";
import { MainModule } from "./main/main.module";
import { MaterialModule } from "./material.module";

const maskConfig: Partial<IConfig> = {
    thousandSeparator: ","
};

@NgModule({
    providers: [{
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: { floatLabel: "always" }
    }],
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AuthModule.forRoot(),
        MaterialModule,
        BrowserAnimationsModule,
        CoreModule,
        MainModule,
        AppRoutingModule,
        NgxMaskModule.forRoot(maskConfig)
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
