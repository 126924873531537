<div class="site-container flex"
     [ngClass]="{'mobile-menu-open': menuOpen}">
    <img routerLink="/"
         class="logo"
         src="/assets/images/cf-insights-logo.svg"
         alt="Council on Foundations | CF Insights" />


    <ng-container *ngIf="showNavigationButtons$ | async; else showBasicButtons">
        <nav>
            <ng-container *ngIf="(authStatus$ | async); else home">
                <div *cfiRequiredRole="role.Admin"
                     class="link admin-submenu-link"
                     [matMenuTriggerFor]="adminSubmenu">
                    User panel
                    <span class="material-icons ml-1">
                        expand_more
                    </span>
                </div>

                <mat-menu class="user-panel"
                          xPosition="before"
                          #adminSubmenu="matMenu">
                    <ng-container *ngFor="let route of userRoutes">
                        <button mat-menu-item
                                class="link"
                                [routerLink]="route.url"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="route.routerLinkActiveOptions
                                ? route.routerLinkActiveOptions : { exact: false }">
                            {{ route.label }}
                        </button>
                    </ng-container>
                    <a mat-menu-item
                       class="link"
                       href="https://cof.org/cf-insights/faqs"
                       target="_blank">
                        FAQ
                    </a>
                </mat-menu>

                <div *cfiRequiredRole="role.Admin"
                     class="mobile-only-menu">
                    <ng-container *ngTemplateOutlet="userLinks"></ng-container>
                </div>

                <ng-container *cfiRequiredRole="role.Admin; else userLinks">
                    <ng-container *cfiRequiredRole="role.Admin">
                        <div *ngFor="let route of adminRoutes"
                             class="link"
                             [routerLink]="route.url"
                             routerLinkActive="active"
                             [routerLinkActiveOptions]="route.routerLinkActiveOptions ? route.routerLinkActiveOptions : { exact: false }">
                            {{ route.label }}
                        </div>
                    </ng-container>

                    <div class="mobile-only-menu">
                        <div *ngFor="let route of manageRoutes"
                             class="link"
                             [routerLink]="route.url"
                             routerLinkActive="active"
                             [routerLinkActiveOptions]="route.routerLinkActiveOptions ? route.routerLinkActiveOptions : { exact: false }">
                            Manage {{ route.label }}
                        </div>
                    </div>
                </ng-container>

                <div *cfiRequiredRole="role.Admin"
                     class="link admin-submenu-link"
                     [matMenuTriggerFor]="manageSubmenu">
                    Manage
                    <span class="material-icons ml-1">
                        expand_more
                    </span>
                </div>

                <mat-menu class="user-panel"
                          xPosition="before"
                          #manageSubmenu="matMenu">
                    <ng-container *ngFor="let route of manageRoutes">
                        <button mat-menu-item
                                class="link"
                                [routerLink]="route.url">
                            {{ route.label }}
                        </button>
                    </ng-container>
                </mat-menu>

                <ng-template #userLinks>
                    <ng-container *ngFor="let route of userRoutes">
                        <div class="link"
                             [routerLink]="route.url"
                             routerLinkActive="active"
                             [routerLinkActiveOptions]="route.routerLinkActiveOptions ? route.routerLinkActiveOptions : { exact: false }">
                            {{ route.label }}
                        </div>
                    </ng-container>
                    <a class="link"
                       href="https://cof.org/cf-insights/faqs"
                       target="_blank">
                        FAQ
                    </a>
                    <div *cfiRequiredRole="role.IpaAdmin"
                         class="separator"></div>
                    <div *cfiRequiredRole="role.IpaAdmin"
                         class="link margin-left border-left"
                         routerLink="/surveys"
                         routerLinkActive="active"
                         [routerLinkActiveOptions]="{ exact: true }">
                        Surveys
                    </div>
                </ng-template>
            </ng-container>

            <ng-template #home>
                <div class="link"
                     routerLink="/"
                     routerLinkActive="active"
                     [routerLinkActiveOptions]="{ exact: true }">
                    Home
                </div>
            </ng-template>
        </nav>

        <div class="buttons">
            <ng-container *ngIf="authStatus$ | async else notLoggedIn">
                <button mat-flat-button
                        color="white"
                        (click)="signout()">
                    Logout
                </button>
            </ng-container>

            <ng-template #notLoggedIn>
                <button mat-flat-button
                        color="primary"
                        (click)="login()">
                    Login
                </button>
            </ng-template>
        </div>
        <button class="mobile-nav-trigger"
                aria-label="Open the menu"
                (click)="menuOpen=!menuOpen">
            <span></span>
            <span></span>
            <span></span>
        </button>
    </ng-container>
</div>

<ng-template #showBasicButtons>
    <div class="buttons-basic">
        <ng-container *ngIf="isOnBasicPage()">
            <button mat-flat-button
                    color="white"
                    class="secondary-button"
                    (click)="signout()">
                Logout
            </button>
        </ng-container>
    </div>
</ng-template>
