import { Pipe, PipeTransform } from "@angular/core";

import * as data from "./tooltips.json";

@Pipe({
    name: "info"
})
export class InfoPipe implements PipeTransform {
    transform(infoPath: string): string {
        const info = (data as any).default;
        return (infoPath.split(".").reduce((p, c) => (p && p[c]) as string || null, info) as string) || infoPath;
    }
}
