import { Component, Input } from "@angular/core";
import { PreviousValues } from "@app/core/model/previous-values.model";

@Component({
    selector: "cfi-previous-values",
    templateUrl: "./previous-values.component.html",
    styleUrls: ["./previous-values.component.scss"]
})
export class PreviousValuesComponent {
    @Input() values: PreviousValues;

    readonly emptyMark = "-";
}
