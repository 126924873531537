<div class="site-container grid">
    <div class="col-6">
        <h1>Request denied</h1>
        <h4>Your request to access CF Insights was denied.<br />If you think this was done in error, please
            <a href="mailto:cfinsights@cof.org">contact us</a>.</h4>
        <button mat-flat-button
                (click)="goToHomePage()"
                class="mb-4 mt-3"
                color="primary">Go to homepage</button>
    </div>
</div>
