export enum Role {
    Admin = "urn:candid:cfi:role:admin",
    IpaAdmin = "urn:candid:cfi:role:ipa-admin"
}

export enum InputMaskPatterns {
    OnlyNumber = "separator.2",
    EIN = "000000000",
    NaturalNumber = "separator.0",
    Year = "9999"
}

export enum FieldLengthLimit {
    short = 50,
    long = 350,
    extraLong = 500
}

export enum PageSize {
    small = 25,
    large = 100
}
