import { Directive, ElementRef, OnInit, Renderer2 } from "@angular/core";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "form"
})
export class FormDirective implements OnInit {
    constructor(
        private _hostElement: ElementRef,
        private _renderer: Renderer2
    ) { }

    ngOnInit(): void {
        if (!this._hostElement.nativeElement.attributes.autocomplete) {
            this._renderer.setAttribute(this._hostElement.nativeElement, "autocomplete", "off");
        }
    }
}
