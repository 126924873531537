import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment
} from "@angular/router";
import { AuthService } from "@app/auth";
import { Role } from "@app/core/common/enums";
import { Observable } from "rxjs";
import { first, map, tap } from "rxjs/operators";

export interface PermissionData {
    roles: Role[];
}

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private _router: Router, private _authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isAuthenticated(state.url, route.data as PermissionData);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        const fullPath = segments.reduce((path, currentSegment) => `${path}/${currentSegment.path}`, "");
        const { hash } = window.location;
        return this.isAuthenticated(fullPath + hash, route.data as PermissionData);
    }

    private isAuthenticated(url: string, data?: PermissionData): Observable<boolean> {
        return this._authService.authStatus$.pipe(
            first(),
            tap(isAuthenticated => {
                if (!isAuthenticated) {
                    this._router.navigate(["login"], { queryParams: { redirect: url }, replaceUrl: true });
                }
            }),
            map(isAuthenticated => {
                if (isAuthenticated && data?.roles) {
                    if (!this._authService.hasAnyOfRoles(data.roles)) {
                        this._router.navigate(["unauthorized"]);
                        return false;
                    }
                }

                return isAuthenticated;
            })
        );
    }
}
