<ng-container *ngIf="values">
    <ng-container *ngIf="values.length > 0">
        Previously:
        <span *ngFor="let value of values"
              class="value">
            {{ value ? value : emptyMark }}
        </span>
    </ng-container>

    <ng-container *ngIf="values.length === 0">
        Previously: <span class="value">{{ emptyMark }}</span>
    </ng-container>
</ng-container>
