import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { FoundationMember } from "@app/core/model/foundation.model";
import { FoundationService } from "@app/core/services/foundation.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface UserFoundationResolverData {
    isMember: boolean;
    foundationName: string;
    isGhostFoundation: boolean;
}

@Injectable({
    providedIn: "root"
})
export class UserFoundationResolver implements Resolve<UserFoundationResolverData> {
    constructor(private _foundationService: FoundationService) { }

    resolve(): Observable<UserFoundationResolverData> {
        return this._foundationService.getMyFoundation().pipe(map(x => ({
            isMember: x.insightsMember === FoundationMember.Member,
            foundationName: x.foundationName,
            isGhostFoundation: x.isGhostFoundation
        })));
    }
}
