<mat-card>
    <section class="page-info-content">
        <div>
            <h1>
                {{errorCode}}
            </h1>
        </div>
        <div>
            <p>{{message}}</p>
            <p>Go back to the <a routerLink="/">homepage</a>.
            </p>
        </div>
    </section>
</mat-card>