import { NgIf } from "@angular/common";
import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "@app/auth";
import { Role } from "@app/core/common/enums";

@Directive({
    selector: "[cfiRequiredRole]"
})
export class RequiredRoleDirective extends NgIf {
    constructor(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef, private _authService: AuthService) {
        super(viewContainerRef, templateRef);
    }

    @Input() set cfiRequiredRole(role: Nullable<Role[] | Role>) {
        if (role === null || !role) {
            this.ngIf = true;
        } else {
            this.ngIf = Array.isArray(role)
                ? this._authService.hasAnyOfRoles(role)
                : this._authService.hasRole(role);
        }
    }

    @Input() set cfiRequiredRoleElse(templateElse: TemplateRef<any>) {
        this.ngIfElse = templateElse;
    }
}
