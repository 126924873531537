import { SearchCriteria } from "@app/shared/paginator/paginator.models";

export interface User {
    id: number;
    accountId: string;
    status: UserStatusKeys;
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    foundation: UserFoundation;
    requestedFoundationName: string;
    administrator: boolean;
    ipaAdministrator: boolean;
}

export interface UserFoundation {
    id: number;
    name: string;
    urn: string;
    urnExternal: string;
}

export enum UserStatus {
    Pending = "Pending",
    Approved = "Approved",
    Rejected = "Rejected"
}

export type UserStatusKeys = keyof typeof UserStatus;

export interface UserSearchCriteria extends SearchCriteria {
    status: UserStatusKeys;
}

export interface UserCreate {
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    requestedFoundationName: string;
    password: string;
}

export interface UserUpdate {
    status?: UserStatusKeys;
    foundationUrn?: string;
    foundationUrnExternal?: string;
    administrator?: boolean;
    ipaAdministrator?: boolean;
    jobTitle?: string;
    accountId?: string;
}
