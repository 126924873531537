import { Injectable } from "@angular/core";
import { UserInfo } from "@app/core/model/user-info.model";
import { ApiClientService } from "@app/core/services/api-client.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class UserInfoService {
    constructor(private _apiClientService: ApiClientService) { }

    get(): Observable<UserInfo> {
        return this._apiClientService.get<UserInfo>(`${appConfig.apiUrl}/user-info`);
    }
}
