import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { Role } from "@app/core/common/enums";
import { AuthGuard } from "@app/core/guards/auth.guard";
import { UserFoundationResolver } from "@app/core/resolvers/user-foundation.resolver";

const routes: Routes = [
    {
        path: "survey-list",
        loadChildren: () => import("./survey-list/survey-list.module").then(m => m.SurveyListModule),
        canLoad: [AuthGuard],
        data: {
            title: "My surveys"
        }
    },
    {
        path: "survey",
        loadChildren: () => import("./survey/survey.module").then(m => m.SurveyModule),
        canLoad: [AuthGuard],
        data: {
            title: "Survey"
        }
    },
    {
        path: "surveys",
        loadChildren: () => import("./surveys/surveys.module").then(m => m.SurveysModule),
        canLoad: [AuthGuard],
        data: {
            roles: [
                Role.Admin,
                Role.IpaAdmin
            ],
            title: "Surveys"
        }
    },
    {
        path: "benchmarking",
        loadChildren: () => import("./benchmarking/benchmarking.module").then(m => m.BenchmarkingModule),
        canLoad: [AuthGuard],
        resolve: { foundation: UserFoundationResolver },
        data: {
            title: "Benchmarking"
        }
    },
    {
        path: "foundations",
        loadChildren: () => import("./foundations/foundations.module").then(m => m.FoundationsModule),
        canLoad: [AuthGuard],
        data: {
            title: "Foundations"
        }
    },
    {
        path: "user-accounts",
        loadChildren: () => import("./accounts/accounts.module").then(m => m.AccountsModule),
        canLoad: [AuthGuard],
        data: {
            roles: [
                Role.Admin
            ],
            title: "User accounts"
        }
    },
    {
        path: "manage",
        loadChildren: () => import("./manage/manage.module").then(m => m.ManageModule),
        canLoad: [AuthGuard],
        data: {
            roles: [
                Role.Admin
            ],
            title: "Manage"
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FeaturesRoutingModule { }
