import { Component, Input } from "@angular/core";

@Component({
    selector: "cfi-info-tooltip",
    templateUrl: "./info-tooltip.component.html",
    styleUrls: ["./info-tooltip.component.scss"]
})
export class InfoTooltipComponent {
    @Input() message: string;
}
