import { NgModule } from "@angular/core";

import { ForbiddenRoleDirective } from "./forbidden-role.directive";
import { InvalidControlScrollDirective } from "./invalid-control-scroll.directive";
import { PasswordSwitchTypeDirective } from "./password-switch-type.directive";
import { RequiredRoleDirective } from "./required-role.directive";
import { ViewportDirective } from "./viewport.directive";

const directives = [
    RequiredRoleDirective,
    ForbiddenRoleDirective,
    PasswordSwitchTypeDirective,
    InvalidControlScrollDirective,
    ViewportDirective
];

@NgModule({
    declarations: directives,
    exports: directives
})
export class DirectivesModule { }
