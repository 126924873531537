<cfi-register-container>
    <h2 class="title">Thank you!</h2>
    <h4 class="mb-4">Your registration information has been submitted for approval. You will be notified by email once you have been
        given access.</h4>
    <div class="buttons">
        <button mat-flat-button
                color="primary"
                (click)="goToHomePage()">Go to homepage</button>
    </div>
</cfi-register-container>
