import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "../auth.service";

@Component({
    selector: "cfi-auth-silent-callback",
    templateUrl: "./auth-silent-callback.component.html",
    styleUrls: ["auth-silent-callback.component.scss"]
})
export class AuthSilentCallbackComponent implements OnInit {
    error = false;

    constructor(private _authService: AuthService, private _router: Router) { }

    ngOnInit(): void {
        if (this._router.url.indexOf("error") >= 0) {
            this.error = true;
            return;
        }

        this._authService.completeSilentAuthentication();
    }
}
