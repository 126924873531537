import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Role } from "@app/core/common/enums";
import { UserInfo } from "@app/core/model/user-info.model";
import { UserStatus } from "@app/core/model/user.model";
import { AccountService } from "@app/core/services/account-service";
import { UserInfoService } from "@app/core/services/user-info.service";
import { User, UserManager } from "oidc-client-ts";
import { from, Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { mapTo, switchMap } from "rxjs/operators";

import { AuthSettings } from "./auth.config";

interface AuthenticatedUser {
    user: User;
    info?: UserInfo;
}

@Injectable()
export class AuthService {
    loggingProcess: Observable<boolean>;
    authStatus$: Observable<boolean>;

    private _userManager = new UserManager(AuthSettings.getClientSettings());
    private _authenticatedUser: Nullable<AuthenticatedUser>;
    private _loggingProcess = new BehaviorSubject<boolean>(false);
    private _authStatusSource = new ReplaySubject<boolean>();

    constructor(
        private _router: Router,
        private _userInfoService: UserInfoService,
        private _accountService: AccountService
    ) {
        this.authStatus$ = this._authStatusSource.asObservable();
        this.loggingProcess = this._loggingProcess.asObservable();

        this._userManager.getUser().then(async user => {
            if (!!user && !user.expired) {
                await this.buildAuthenticatedUser(user);
            } else {
                this._userManager.signinSilent().then(async (userResult) => {
                    await this.buildAuthenticatedUser(userResult as User);
                }).catch(() => {
                    this._authStatusSource.next(false);
                });
            }
        });

        this._userManager.events.addUserLoaded(user => {
            if (this._authenticatedUser?.user !== user) {
                this._authenticatedUser = {
                    user,
                    ...this._authenticatedUser
                };
            }
        });

        this._userManager.events.addUserSignedOut(() => {
            this._userManager.removeUser().then(() => {
                this._authenticatedUser = null;
                this._authStatusSource.next(false);
            });
        });
    }

    async login(): Promise<any> {
        if (!this._loggingProcess.value) {
            this._loggingProcess.next(true);
            await this._userManager.signinRedirect({ state: window.location.href });
            this.localStorage?.removeItem("signout");
        }
    }

    loginSilently(): void {
        this._userManager.signinSilent().then(async (userResult) => {
            await this.buildAuthenticatedUser(userResult as User, true);
        });
    }

    async buildAuthenticatedUser(user: User, silentLogin: boolean = false): Promise<void> {
        this._loggingProcess.next(!silentLogin);
        const info = await (this._userInfoService.get()).toPromise();

        this._authenticatedUser = {
            user,
            info
        };

        if (info.registered && info.status === UserStatus.Approved) {
            this._authStatusSource.next(this.isAuthenticated);
        } else {
            if (!info.registered) {
                this._router.navigate(["/additional-data"]);
            } else if (info.status === UserStatus.Pending) {
                this._router.navigate(["/thank-you"]);
            } else if (info.status === UserStatus.Rejected) {
                this._router.navigate(["/request-rejected"]);
            }
            this._authStatusSource.next(false);
        }

        this._loggingProcess.next(false);
    }

    completeAuthentication(): void {
        this._loggingProcess.next(true);

        from(this._userManager.signinRedirectCallback()).pipe(
            switchMap(user => this.sendLoginEvent(user))
        ).subscribe(user => {
            const redirectURL = this.getRedirectURLFromState(user.state as string);
            window.location.replace(redirectURL);
        });
    }

    completeSilentAuthentication(): void {
        from(this._userManager.signinSilentCallback()).subscribe();
    }

    signout(): void {
        this._userManager.signoutRedirect().then(() => {
            this._authenticatedUser = null;
            this._authStatusSource.next(false);
            this.localStorage?.setItem("signout", "true");
        });
    }

    getAuthorizationToken(): Promise<Nullable<string>> {
        return this._userManager.getUser().then(user => {
            if (!user) {
                return null;
            }
            return `${user.token_type} ${user.id_token as string}`;
        });
    }

    getRedirectURLFromState(state: string): string {
        return new URL(state).searchParams.get("redirect") || "/";
    }

    hasRole(role: Role): boolean {
        return this._authenticatedUser?.info?.roles?.includes(role) || false;
    }

    hasAnyOfRoles(roles: Role[]): boolean {
        return this._authenticatedUser?.info?.roles?.some(r => roles.findIndex(role => role === r) > -1) || false;
    }

    private sendLoginEvent(user: User): Observable<User> {
        return this._accountService.loginEvent({
            eventUrl: window.location.origin,
            sessionId: this.sid,
            timestamp: new Date().toISOString()
        }).pipe(mapTo(user));
    }

    get name(): Nullable<string> {
        return this._authenticatedUser?.user?.profile.name;
    }

    get email(): Nullable<string> {
        return this._authenticatedUser?.user?.profile.email;
    }

    get familyName(): Nullable<string> {
        return this._authenticatedUser?.user?.profile.family_name;
    }

    get givenName(): Nullable<string> {
        return this._authenticatedUser?.user?.profile.given_name;
    }

    get userId(): number {
        return this._authenticatedUser?.info?.id || -1;
    }

    get sid(): string {
        const token = this._authenticatedUser?.user?.id_token as string;
        const parseJwt = JSON.parse(atob(token.split(".")[1]));
        return parseJwt.at_hash as string;
    }

    get isAuthenticated(): boolean {
        return !!this._authenticatedUser?.user;
    }

    get isRegistered(): boolean {
        return this._authenticatedUser?.info?.registered ?? false;
    }

    get isAdmin(): boolean {
        return this.hasRole(Role.Admin);
    }

    get isIpaAdmin(): boolean {
        return this.hasRole(Role.IpaAdmin);
    }

    get localStorage(): Nullable<Storage> {
        if (typeof window !== "undefined") {
            return localStorage;
        }
        return null;
    }

    get tokenExpired(): boolean {
        const token = this._authenticatedUser?.user?.id_token as string;
        const parseJwt = JSON.parse(atob(token.split(".")[1]));
        const expDate = new Date(0);
        expDate.setUTCSeconds(parseJwt.exp);
        const expISODate = expDate.toISOString();
        const checkISODate = new Date().toISOString();
        return new Date(checkISODate) >= new Date(expISODate);
    }
}
