import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { DialogData } from "../dialog-models";

@Component({
    templateUrl: "./dialog-action.component.html",
    styleUrls: ["./dialog-action.component.scss"]
})
export class DialogActionComponent {
    constructor(public dialogRef: MatDialogRef<DialogActionComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}
