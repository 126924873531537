import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./main/home/home.component";
import { PageForbiddenComponent } from "./main/page-info/pages/page-forbidden.component";
import { PageNotFoundComponent } from "./main/page-info/pages/page-not-found.component";

const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
        pathMatch: "full"
    },
    {
        path: "unauthorized",
        component: PageForbiddenComponent
    },
    {
        path: "not-found",
        component: PageNotFoundComponent
    },
    {
        path: "**",
        redirectTo: "not-found",
        pathMatch: "full"
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
            onSameUrlNavigation: "reload"
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
