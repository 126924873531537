import { Pipe, PipeTransform } from "@angular/core";
import { InputMaskPatterns } from "@app/core/common/enums";
import { MaskPipe } from "ngx-mask";

@Pipe({
    name: "customCurrency"
})
export class CustomCurrencyPipe implements PipeTransform {
    masks = InputMaskPatterns;
    constructor(private _mask: MaskPipe) { }
    transform(value: number | null): string {
        const negative = value && value < 0 ? "-" : "";
        return value
            ? `$ ${negative}${this._mask.transform(value.toFixed(2), this.masks.OnlyNumber, ",")}`
            : "$ 0.00";
    }
}
