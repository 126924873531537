import { Component } from "@angular/core";

import { PageInfoTemplateComponent } from "../page-info-template.component";

@Component({
    templateUrl: "../page-info-template.component.html",
    styleUrls: ["../page-info-template.component.scss"]
})
export class PageForbiddenComponent extends PageInfoTemplateComponent {
    constructor() {
        super();
        this.errorCode = "403 ERROR";
        this.message = "You don't have permission to access this page.";
    }
}
