import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FeaturesModule } from "@app/features/features.module";
import { MaterialModule } from "@app/material.module";
import { DialogsModule } from "@app/shared/dialogs/dialogs.module";
import { DirectivesModule } from "@app/shared/directives/directives.module";
import { LoaderModule } from "@app/shared/loader/loader.module";

import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { PageInfoModule } from "./page-info/page-info.module";
import { TopComponent } from "./top/top.component";

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        MaterialModule,
        FeaturesModule,
        PageInfoModule,
        RouterModule,
        DialogsModule,
        LoaderModule
    ],
    declarations: [
        NavbarComponent,
        HomeComponent,
        FooterComponent,
        TopComponent
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
        HomeComponent,
        TopComponent
    ]
})
export class MainModule { }
