import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from "@angular/router";
import { AuthService } from "@app/auth";
import { Role } from "@app/core/common/enums";
import { Observable, of } from "rxjs";

export interface PermissionData {
    roles: Role[];
}

@Injectable({
    providedIn: "root"
})
export class AdditionalDataGuard implements CanActivate {
    constructor(private _router: Router, private _authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.isAuthenticated(state.url, route.data as PermissionData);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private isAuthenticated(url: string, data?: PermissionData): Observable<boolean> {
        if (!this._authService.isAuthenticated) this._router.navigate(["login"]);
        else if (this._authService.isRegistered) this._router.navigate(["/"]);

        return of(this._authService.isAuthenticated);
    }
}
