<mat-card *ngIf="error">
    <section class="auth-callback">
        <div>
            <h1>
                Oops!
            </h1>
        </div>
        <div>
            <p>There was an error, please try to <a routerLink="/login">login again</a>.</p>
        </div>
    </section>
</mat-card>
