/* eslint-disable @typescript-eslint/naming-convention */
import { UserManagerSettings } from "oidc-client-ts";

export class AuthSettings {
    static getClientSettings(): UserManagerSettings {
        return {
            authority: appConfig.authorizationUrl,
            client_id: appConfig.clientId,
            client_secret: appConfig.clientSecret,
            redirect_uri: `${appConfig.clientUrl}/auth-callback`,
            response_type: "code",
            scope: "openid profile email",
            loadUserInfo: true,
            automaticSilentRenew: false,
            silent_redirect_uri: `${appConfig.clientUrl}/auth-silent-callback`,
            post_logout_redirect_uri: appConfig.logoutUrl
        };
    }
}
