import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MaterialModule } from "@app/material.module";
import { MaskPipe, NgxMaskModule } from "ngx-mask";

import { DirectivesModule } from "./directives/directives.module";
import { FormDirective } from "./form/form.directive";
import { InfoTooltipModule } from "./info-tooltip/info-tooltip.module";
import { LoaderModule } from "./loader/loader.module";
import { CustomCurrencyPipe } from "./pipes/custom-currency.pipe";
import { FormControlPipe } from "./pipes/form-control.pipe";
import { FormGroupPipe } from "./pipes/form-group.pipe";
import { YesNoPipe } from "./pipes/yes-no.pipe";
import { PreviousValuesComponent } from "./previous-values/previous-values.component";
import { ValidationFeedbackComponent } from "./validation-feedback/validation-feedback.component";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        DirectivesModule,
        LoaderModule,
        NgxMaskModule,
        InfoTooltipModule
    ],
    declarations: [
        ValidationFeedbackComponent,
        FormDirective,
        CustomCurrencyPipe,
        PreviousValuesComponent,
        YesNoPipe,
        FormGroupPipe,
        FormControlPipe
    ],
    exports: [
        CommonModule,
        CustomCurrencyPipe,
        MaterialModule,
        ValidationFeedbackComponent,
        DirectivesModule,
        FormDirective,
        NgxMaskModule,
        LoaderModule,
        InfoTooltipModule,
        PreviousValuesComponent,
        YesNoPipe,
        FormGroupPipe,
        FormControlPipe
    ],
    providers: [
        YesNoPipe,
        { provide: MaskPipe, useClass: MaskPipe }
    ]
})
export class SharedModule { }
