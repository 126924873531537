import { Component } from "@angular/core";

import { RegisterPage } from "../form-section.class";

@Component({
    selector: "cfi-rejected",
    templateUrl: "./rejected.component.html",
    styleUrls: ["./rejected.component.scss"]
})
export class RejectedComponent extends RegisterPage { }
