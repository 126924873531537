import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiClientService } from "./api-client.service";

export interface WelcomePageText<T> {
    announcementHeader: T;
    surveysHeader: T;
    surveysDescription: T;
    benchmarkHeader: T;
    benchmarkDescription: T;
}

export interface WelcomePageSections {
    surveyNotStarted: WelcomePageText<string>;
    // TODO: API need to be finished #110
    // surveyInProgress: WelcomePageText<string>;
    // surveyIsComplete: WelcomePageText<string>;
    // nonMemberView: WelcomePageText<string>;
}

@Injectable({
    providedIn: "root"
})
export class GeneralService {
    constructor(private _apiClientService: ApiClientService) { }

    saveWelcomePageText(data: WelcomePageSections): Observable<void> {
        return this._apiClientService.post(`${appConfig.apiUrl}/manage/welcome-page`, { data });
    }

    getWelcomePageTextForEdit(): Observable<WelcomePageSections> {
        return this._apiClientService.get(`${appConfig.apiUrl}/manage/welcome-page`);
    }

    getWelcomePageTextForDisplay(): Observable<WelcomePageText<string>> {
        return this._apiClientService.get(`${appConfig.apiUrl}/general/welcome-page`);
    }
}
