import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserCreate } from "@app/core/model/user.model";
import { UserService } from "@app/core/services/user.service";
import { indicate } from "@app/shared/operators";
import { CustomValidators } from "@app/shared/validation-feedback/validators/custom.validators";
import { Subject } from "rxjs";

import { RegisterPage } from "../form-section.class";
import { PasswordValidator } from "./password-strength.validator";

@Component({
    selector: "cfi-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"]
})
export class RegisterComponent extends RegisterPage {
    registerForm: FormGroup;

    isLoading$ = new Subject<boolean>();

    constructor(
        private _fb: FormBuilder,
        private _userService: UserService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) {
        super();
        this.registerForm = this._fb.group({
            firstName: ["", [Validators.required]],
            lastName: ["", [Validators.required]],
            email: ["", [Validators.required, CustomValidators.email]],
            requestedFoundationName: ["", [Validators.required]],
            jobTitle: [""],
            password: ["", [PasswordValidator.strength]],
            confirmPassword: ["", [Validators.required]]
        }, {
            validators: [PasswordValidator.confirmed("password", "confirmPassword")]
        });
    }

    register(): void {
        if (this.registerForm.valid) {
            this._userService.create({
                email: this.registerForm.value.email,
                firstName: this.registerForm.value.firstName,
                lastName: this.registerForm.value.lastName,
                jobTitle: this.registerForm.value.jobTitle,
                requestedFoundationName: this.registerForm.value.requestedFoundationName,
                password: this.registerForm.value.password
            } as UserCreate).pipe(
                indicate(this.isLoading$)
            ).subscribe(_ => {
                this._router.navigate(["thank-you"], { relativeTo: this._activatedRoute });
            });
        }
    }
}
