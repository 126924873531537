<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content
     [innerHTML]="data.description"></div>
<div class="dialog__buttons"
     mat-dialog-actions>
    <button mat-flat-button
            color="white"
            [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button
            color="primary"
            [mat-dialog-close]="true"
            cdkFocusInitial>Ok</button>
</div>