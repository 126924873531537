import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiClientService } from "./api-client.service";

export interface LoginEventData {
    timestamp: string;
    sessionId: string;
    eventUrl: string;
}

@Injectable({
    providedIn: "root"
})
export class AccountService {
    constructor(private _apiClientService: ApiClientService) { }

    loginEvent(loginEventData: LoginEventData): Observable<void> {
        return this._apiClientService.post(`${appConfig.apiUrl}/account/login-event`, { data: loginEventData });
    }

    logout(): Observable<boolean> {
        return this._apiClientService.put<boolean>(`${appConfig.apiUrl}/account/logout`);
    }
}
