<ng-container *ngIf="(authStatus$ | async) && (showHelpBar$ | async)">
    <div *cfiForbiddenRole="role.Admin"
         class="footer-help">
        <div class="site-container">
            Do you need any help? <a href="mailto:cfinsights@cof.org">Contact us</a>.
        </div>
    </div>
</ng-container>
<div class="footer">
    <div class="site-container flex">
        <div class="left">
            <div class="cof-logo-wrap">
                <a href="https://cof.org//"
                   target="_blank">
                    <img src="assets/images/cof-logo.svg"
                         alt="CoF logo">
                </a>
            </div>
            <span class="text">©2024 Council on Foundations. All rights reserved. <a
                href="https://cof.org/content/privacy-policy"
                target="_blank">Privacy policy.</a>
            </span>
        </div>
    </div>
</div>
