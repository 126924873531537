import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@app/material.module";
import { SharedModule } from "@app/shared/shared.module";

import { AdditionalDataFormComponent } from "./additional-data-form/additional-data-form.component";
import { RegisterContainerComponent } from "./register-container/register-container.component";
import { RegisterRoutingModule } from "./register-routing.module";
import {
    PasswordValidatorFeedbackComponent
} from "./register/password-validator-feedback/password-validator-feedback.component";
import { RegisterComponent } from "./register/register.component";
import { RejectedComponent } from "./rejected/rejected.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";

@NgModule({
    imports: [
        CommonModule,
        RegisterRoutingModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedModule
    ],
    declarations: [
        RegisterComponent,
        AdditionalDataFormComponent,
        ThankYouComponent,
        RegisterContainerComponent,
        RejectedComponent,
        PasswordValidatorFeedbackComponent
    ]
})
export class RegisterModule { }
