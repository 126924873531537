import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "@app/auth";
import { Role } from "@app/core/common/enums";
import { CandidPopupService } from "@app/core/services/candid-popup.service";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";

@Component({
    selector: "cfi-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {
    role = Role;
    showHelpBar$: Observable<boolean>;
    authStatus$: Observable<boolean> = this._authService.authStatus$;

    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _candidPopupService: CandidPopupService
    ) {
        this.showHelpBar$ = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(navigation => {
                const { url } = (navigation as NavigationEnd);
                return url !== "/";
            }),
            distinctUntilChanged()
        );
    }

    contact(): void {
        this._candidPopupService.openSupport();
    }
}
