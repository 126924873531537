import { Component, OnInit } from "@angular/core";
import { AuthService } from "@app/auth";

@Component({
    template: "Loading..."
})
export class LoginComponent implements OnInit {
    constructor(private _authService: AuthService) { }

    ngOnInit(): void {
        this._authService.login();
    }
}
