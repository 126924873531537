import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { fromEvent } from "rxjs";
import { debounceTime, first } from "rxjs/operators";

@Directive({
    selector: "[cfiInvalidControlScroll]"
})
export class InvalidControlScrollDirective {
    @Input() scrollDelayDueToAnimation = false;
    @Input() scrollOffset = 0;

    constructor(private _el: ElementRef<HTMLElement>, private _formGroupDir: FormGroupDirective) { }

    @HostListener("ngSubmit") onSubmit(): void {
        if (this._formGroupDir.control.invalid) {
            setTimeout(() => {
                this.scrollToFirstInvalidControl();
            }, this.scrollDelayDueToAnimation ? 500 : 0);
        }
    }

    private scrollToFirstInvalidControl() {
        const firstInvalidControl = this._el.nativeElement.querySelector(".ng-invalid") as HTMLElement;

        window.scroll({
            top: this.getTopOffset(firstInvalidControl) - this.scrollOffset,
            left: 0,
            behavior: "smooth"
        });

        fromEvent(window, "scroll")
            .pipe(
                debounceTime(100),
                first()
            ).subscribe(() => firstInvalidControl.focus());
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
}
